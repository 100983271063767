    var timer = 0;
    var currentWidth = window.innerWidth;
	$(window).resize(function(){
        if (currentWidth == window.innerWidth) {
            return;
        }
        if (timer > 0) {
            clearTimeout(timer);
        }
 
        timer = setTimeout(function () {
            location.reload();
        }, 200);
		
	});    var timer = 0;
    var currentWidth = window.innerWidth;
	$(window).resize(function(){
        if (currentWidth == window.innerWidth) {
            return;
        }
        if (timer > 0) {
            clearTimeout(timer);
        }
 
        timer = setTimeout(function () {
            location.reload();
        }, 200);
		
	});(function ($) {

	
  // 追従 ナビ
	$(function() {
		var headNav = $("header");
		//scrollだけだと読み込み時困るのでloadも追加
		$(window).on('load scroll', function () {
			//現在の位置が500px以上かつ、クラスfixedが付与されていない時
			if($(this).scrollTop() > 150 && headNav.hasClass('fixed') == false) {
				//headerの高さ分上に設定
				headNav.css({"top": '-100px'});
				//クラスfixedを付与
				headNav.addClass('fixed');
				//位置を0に設定し、アニメーションのスピードを指定
				headNav.animate({"top": 0},500);
			}
			//現在の位置が300px以下かつ、クラスfixedが付与されている時にfixedを外す
			else if($(this).scrollTop() < 150 && headNav.hasClass('fixed') == true){
				headNav.removeClass('fixed');
			}
		});
	});


  // スムーズスクロール
  $(function(){
    $('a[href^=#]').click(function() {
      var speed = 500;
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var headerHeight = 60; //固定ヘッダーの高さ
      var position = target.offset().top - headerHeight; //ターゲットの座標からヘッダの高さ分引く
      $('body,html').animate({scrollTop:position}, speed, 'swing');
      return false;
    });
  });


  //追従 ボタン
  $(function() {
      var topBtn = $('#btnTop');    
      topBtn.hide();
      //スクロールが数値に達したらボタン表示
      $(window).scroll(function () {
          if ($(this).scrollTop() > 1000) {
          //ボタンの表示方法
              topBtn.fadeIn();
          } else {
          //ボタンの非表示方法
              topBtn.fadeOut();
          }
      });
  });

	
  // スマホメニュー
  $(function(){
		var $nav   = $('#navArea');
		var $btn   = $('.toggleBtn');
		var $mask  = $('#Mask');
		var open   = 'open'; // class
		// menu open close
		$btn.on( 'click', function() {
			if ( ! $nav.hasClass( open ) ) {
				$nav.addClass( open );
			} else {
				$nav.removeClass( open );
			}
		});
		
		// mask close
		$mask.on('click', function() {
			$nav.removeClass( open );
		});
		
    $('#navArea a').on('click', function(){
      $('#navArea').removeClass('open');
    })
  });
		
  $(function(){
    $("#navArea ul > li > *").on("click", function() {
      $(this).next().slideToggle();

			$("#navArea ul > li > *").not(this).removeClass("open");
			//クリックした要素以外の全てのopenを取る

			$(this).toggleClass("open");
			//thisにopenクラスを付与
    });
  });
		
		
	// reload
	if(!navigator.userAgent.match(/(iPhone|iPad|iPod|Android)/)){
    //ここに書いた処理はスマホ閲覧時は無効となる
    $(function(){
      var timer = false;
      $(window).resize(function() {
      if (timer !== false) {
      clearTimeout(timer);
      }
      timer = setTimeout(function() {
      //リロードする
      location.reload();
      }, 200);
      });
    });
   }
	

}(jQuery));